import {useQueryClient} from '@tanstack/react-query'
import cn from 'classnames'
import {FormikProps} from 'formik'
import * as React from 'react'
import {Accordion, Button, Col, Row, Stack} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import {StatusBadge} from './Common'
import {CommunicationForm, FormValues} from './forms/CommunicationForm'
import * as styles from './Communications.module.scss'
import {InvestorCommunicationState} from '~src/api'
import {ButtonWithIcon} from '~src/components/ButtonWithIcon/ButtonWithIcon'
import {Callout} from '~src/components/Callout/Callout'
import Modal from '~src/components/Modal/Modal'
import {
    useDeleteCommunication,
    usePreviewCommunication,
    useSetHasSeen,
    useSubmitCommunication,
} from '~src/hooks/communications'
import {useCurrentCompany} from '~src/hooks/useCurrentCompany'
import {useCurrentStaff} from '~src/hooks/useCurrentStaff'
import {WATCHLIST_COMMS_PRICE_ACKNOWLEDGEMENT_FLAG} from '~src/util/constants'

export const NewCommunication = () => {
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const [showUnsavedChangesModal, setShowUnsavedChangesModal] = React.useState(false)
    const [showConfirmPreviewModal, setShowConfirmPreviewModal] = React.useState(false)
    const [errorMessage, setErrorMessage] = React.useState('')
    const [showErrorModal, setShowErrorModal] = React.useState(false)
    const [showDeleteCommModal, setShowDeleteCommModal] = React.useState(false)
    const [showConfirmSendModal, setShowConfirmSendModal] = React.useState(false)

    const formRef = React.useRef<FormikProps<FormValues>>(null)
    const formIsSubmitting = !!(formRef.current && formRef.current.isSubmitting)

    // find the existing communication if there is one
    const {communicationId} = useParams()
    const currentCompany = useCurrentCompany()
    const investorComms = currentCompany.investor_communications
    const existingComm = investorComms.find(c => c.id === communicationId)
    const existingCommIsDraft = !existingComm || existingComm.state === 'DRAFT'
    const existingCommIsSubmitted = !!existingComm && !!existingComm.submitted_at

    const currentStaff = useCurrentStaff()

    const hasAcknowledgedPricingChange = currentCompany.has_seen.includes(WATCHLIST_COMMS_PRICE_ACKNOWLEDGEMENT_FLAG)

    const existingCommSendsToWatchlist = ['WATCHLIST', 'ALL'].includes(existingComm?.audience ?? '')
    const [showAcknowledgePricingChange, setShowAcknowledgePricingChange] = React.useState(
        existingCommSendsToWatchlist && !hasAcknowledgedPricingChange && existingCommIsDraft,
    )

    const deleteCommunication = useDeleteCommunication({
        onSuccess: () => {
            navigate(-1)
        },
    })

    const previewCommunication = usePreviewCommunication(
        {
            onSuccess: () => {
                queryClient.invalidateQueries({queryKey: ['company']})
            },
        },
        {
            onError: (errorText: string) => {
                setErrorMessage(errorText)
                setShowErrorModal(true)
            },
        },
    )

    const submitCommunication = useSubmitCommunication({
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['company']})
        },
    })

    const onDelete = () => {
        // only delete comm if it was previously saved
        if (communicationId) {
            deleteCommunication.mutate({
                communicationId,
            })
        } else {
            navigate(-1)
        }
    }

    const onPreview = async () => {
        await onSave()
        if (formRef.current && Object.keys(formRef.current.errors).length === 0) {
            setShowConfirmPreviewModal(true)
        }
    }

    const onPreviewConfirm = () => {
        if (communicationId) {
            // Then send a preview of that content
            previewCommunication.mutate({
                communicationId,
            })
        }
    }

    const onSave = async () => {
        // TODO - handle form errors on save
        if (formRef.current) {
            formRef.current.handleSubmit()

            // This loop waits for the form to finish submitting before it returns the onSave function
            // so that callers can await and see the result of the save
            await new Promise<void>(resolve => {
                const interval = setInterval(() => {
                    if (!formIsSubmitting) {
                        clearInterval(interval)
                        resolve()
                    }
                }, 50)
            })
        }
    }

    const onSubmit = () => {
        if (communicationId) {
            submitCommunication.mutate({
                communicationId,
            })
        }
    }

    const onBack = () => {
        // show modal if there are unsaved changes
        if (formRef.current && formRef.current.initialValues !== formRef.current.values) {
            setShowUnsavedChangesModal(true)
        } else {
            navigate(-1)
        }
    }

    const setHasSeen = useSetHasSeen({
        onSuccess: () => {
            queryClient.invalidateQueries({queryKey: ['company']}) // refresh the has_seen data
        },
    })

    return (
        <div className="container">
            <ButtonWithIcon
                data-testid="button--save-and-close-communication"
                className="mt-3 mb-1"
                variant="ghost"
                leftIcon="arrowLeft"
                iconSize={16}
                onClick={onBack}
            >
                Save and close
            </ButtonWithIcon>
            <div className={cn(styles.container, 'd-flex gap-4 flex-column')}>
                <div className={styles.containerHeader}>
                    <div className="flex-grow-1">
                        <h2 className="h5 m-0">
                            New communication{' '}
                            <StatusBadge status={existingComm?.state || ('DRAFT' as InvestorCommunicationState)} />
                        </h2>
                        <p className="mt-1 ">
                            {existingComm && existingComm.last_saved_at
                                ? `Last save: ${existingComm.last_saved_at
                                      .toLocal()
                                      .toFormat('h:mm a dd/MM/yy')
                                      .toLowerCase()}`
                                : 'Not saved'}
                        </p>
                    </div>
                    <Stack direction="horizontal" gap={2}>
                        {/** TODO - loading states */}
                        <ButtonWithIcon
                            data-testid="button--delete-communication"
                            variant="ghost"
                            leftIcon="trash"
                            iconSize={16}
                            onClick={() => setShowDeleteCommModal(true)}
                            disabled={formIsSubmitting || !existingCommIsDraft}
                        >
                            Delete
                        </ButtonWithIcon>
                        <ButtonWithIcon
                            data-testid="button--preview-communication"
                            variant="outline"
                            leftIcon="mailSearch"
                            iconSize={16}
                            onClick={() => onPreview()}
                            disabled={formIsSubmitting}
                        >
                            Preview
                        </ButtonWithIcon>
                        <ButtonWithIcon
                            data-testid="button--save-communication"
                            variant="outline"
                            leftIcon="saveAll"
                            iconSize={16}
                            onClick={onSave}
                            type="submit"
                            disabled={formIsSubmitting || !existingCommIsDraft}
                        >
                            Save
                        </ButtonWithIcon>
                        <ButtonWithIcon
                            data-testid="button--send-communication"
                            variant="primary"
                            leftIcon="send"
                            iconSize={16}
                            onClick={async () => {
                                await onSave()
                                setShowConfirmSendModal(true)
                            }}
                            disabled={formIsSubmitting || !existingCommIsDraft}
                        >
                            Send
                        </ButtonWithIcon>
                    </Stack>
                </div>
                <hr />

                <Row>
                    <Col>
                        <CommunicationForm
                            existingComm={existingComm}
                            forwardedRef={formRef}
                            existingCommIsDraft={existingCommIsDraft}
                            existingCommIsSubmitted={existingCommIsSubmitted}
                            setShowAcknowledgePricingChange={setShowAcknowledgePricingChange}
                        />
                    </Col>
                    <Col xs={4} className="pt-2">
                        <NewCommunicationAccordion />
                        {showAcknowledgePricingChange && (
                            <Callout type={hasAcknowledgedPricingChange ? 'secondary' : 'warning'}>
                                <>
                                    <p>
                                        <strong>Sending to watchlisters costs a little more</strong>
                                    </p>
                                    <p>
                                        Each email delivered to an individual who has watchlisted your company will cost
                                        10c, any of those recipients who are also investors will be charged at the
                                        standard rate (5c per email).
                                    </p>
                                    {!hasAcknowledgedPricingChange && (
                                        <Button
                                            className="mb-2"
                                            size="sm"
                                            onClick={() => {
                                                setHasSeen.mutate({
                                                    companyId: currentStaff.company_id,
                                                    data: {flag: WATCHLIST_COMMS_PRICE_ACKNOWLEDGEMENT_FLAG},
                                                })
                                            }}
                                        >
                                            Accept pricing
                                        </Button>
                                    )}
                                </>
                            </Callout>
                        )}
                    </Col>
                </Row>
            </div>
            <UnsavedChangesModal
                isExistingComm={!!existingComm}
                isOpen={showUnsavedChangesModal}
                setIsOpen={setShowUnsavedChangesModal}
                onDiscardChanges={() => {
                    navigate(-1)
                }}
                onSave={() => {
                    onSave()
                    navigate(-1)
                }}
            />
            <ConfirmPreviewModal
                isOpen={showConfirmPreviewModal}
                setIsOpen={setShowConfirmPreviewModal}
                onPreviewConfirm={onPreviewConfirm}
                emailAddress={currentStaff.email}
            />
            <DeleteCommunicationModal
                isOpen={showDeleteCommModal}
                setIsOpen={setShowDeleteCommModal}
                onDelete={onDelete}
            />
            <ConfirmSendModal
                isOpen={showConfirmSendModal}
                setIsOpen={setShowConfirmSendModal}
                onConfirm={onSubmit}
                confirmDisabled={showAcknowledgePricingChange && !hasAcknowledgedPricingChange}
                confirmDisabledContext={
                    <Callout type="warning">
                        <p>
                            You need to accept the cost of sending to individuals who have watchlisted your company
                            prior to sending this communication.
                        </p>
                    </Callout>
                }
            />
            <ErrorModal
                isOpen={showErrorModal}
                setIsOpen={setShowErrorModal}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
            />
        </div>
    )
}

const NewCommunicationAccordion = () => {
    return (
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>Allowed communications</Accordion.Header>
                <Accordion.Body>
                    Sharesies currently supports sending shareholder communications relating to annual reports, interim
                    results and quarterly or monthly updates. Emails outside of this scope may not be approved.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>Sharesies review</Accordion.Header>
                <Accordion.Body>
                    We will not edit or change the details of the communications submitted but may ask you to make
                    changes if we see fit. We will endeavour to approve the communications on the same business day. For
                    further questions or help please email{' '}
                    <a href="mailto:companyservices@sharesies.co.nz">companyservices@sharesies.co.nz</a>.
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

interface ModalProps {
    isOpen: boolean
    setIsOpen: (value: boolean) => void
}

interface UnsavedChangesModalProps extends ModalProps {
    isExistingComm: boolean
    onDiscardChanges: () => void
    onSave: () => void
}

const UnsavedChangesModal = ({
    isExistingComm,
    isOpen,
    setIsOpen,
    onDiscardChanges,
    onSave,
}: UnsavedChangesModalProps) => {
    return (
        <Modal
            title="You’ve made changes"
            buttons={[
                {
                    label: 'Discard changes',
                    variant: 'destructive',
                    onClick: () => {
                        onDiscardChanges()
                    },
                    dataTestId: 'button--modal-discard-changes',
                },
                {
                    label: isExistingComm ? 'Save changes' : 'Save as draft',
                    variant: 'primary',
                    onClick: () => {
                        onSave()
                    },
                    leftIcon: 'saveAll',
                    iconSize: 16,
                    dataTestId: 'button--modal-save-changes',
                },
            ]}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            <p>You can save this as a draft or delete the changes you’ve made.</p>
        </Modal>
    )
}

interface DeleteCommunicationModalProps extends ModalProps {
    onDelete: () => void
}

const DeleteCommunicationModal = ({isOpen, setIsOpen, onDelete}: DeleteCommunicationModalProps) => {
    return (
        <Modal
            title="Delete communication?"
            buttons={[
                {
                    label: 'Back',
                    variant: 'ghost',
                },
                {
                    label: 'Yes, delete',
                    variant: 'destructive',
                    onClick: () => {
                        onDelete()
                    },
                },
            ]}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            <p>Double checking you wish to delete this communication.</p>
        </Modal>
    )
}

interface ConfirmPreviewModalProps extends ModalProps {
    onPreviewConfirm: () => void
    emailAddress: string
}
const ConfirmPreviewModal = ({isOpen, emailAddress, setIsOpen, onPreviewConfirm}: ConfirmPreviewModalProps) => {
    return (
        <Modal
            title="Send preview to email"
            buttons={[
                {
                    label: 'Back',
                    variant: 'ghost',
                },
                {
                    label: 'Confirm',
                    variant: 'primary',
                    onClick: () => {
                        onPreviewConfirm()
                    },
                },
            ]}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            <p>You’ll receive a preview of this communication. This will be sent to {emailAddress}.</p>
        </Modal>
    )
}

interface ErrorModalProps extends ModalProps {
    errorMessage: string
    setErrorMessage: (message: string) => void
}
const ErrorModal = ({errorMessage, setErrorMessage, isOpen, setIsOpen}: ErrorModalProps) => {
    return (
        <Modal
            title={`Error: ${errorMessage}`}
            buttons={[
                {
                    label: 'Ok',
                    variant: 'danger',
                    onClick: () => {
                        setErrorMessage('')
                    },
                },
            ]}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            <p>Sorry, it looks like something went wrong.</p>
            <p>
                Please try again in a few moments, or contact us on{' '}
                <a href="mailto:companyservices@sharesies.co.nz">companyservices@sharesies.co.nz</a>.
            </p>
        </Modal>
    )
}

interface ConfirmSendModalProps extends ModalProps {
    onConfirm: () => void
    confirmDisabled?: boolean
    confirmDisabledContext?: JSX.Element
}

const ConfirmSendModal = ({
    isOpen,
    setIsOpen,
    onConfirm,
    confirmDisabled,
    confirmDisabledContext,
}: ConfirmSendModalProps) => {
    return (
        <Modal
            title="Confirm send"
            buttons={[
                {
                    label: 'Back',
                    variant: 'ghost',
                    dataTestId: 'button--modal-back',
                },
                {
                    label: 'Confirm send',
                    variant: 'primary',
                    onClick: () => {
                        if (onConfirm) {
                            onConfirm()
                        }
                    },
                    dataTestId: 'button--modal-confirm-send',
                    disabled: confirmDisabled,
                },
            ]}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
        >
            <p>
                We’ll give your message a quick once over to make sure it’s looking good to send out to your
                shareholders.
            </p>
            <p>If there are any changes, we’ll be in touch.</p>
            {confirmDisabled ? confirmDisabledContext : <></>}
        </Modal>
    )
}
