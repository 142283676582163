import * as React from 'react'
import pineappleLogo from '~src/_assets/images/logos/pineapple-logo.svg'

export const GeneralError = () => {
    return (
        <div className="mx-auto mt-5 text-center">
            <img src={pineappleLogo} className="sharesies-open-logo" alt="Sharesies Open" />
            <h3 className="mt-2">Error</h3>
            <p>We’re not quite sure what’s gone wrong.</p>
            <p>
                You can go back or <a href="mailto:companyservices@sharesies.co.nz">email us</a> if you need a hand.
            </p>
        </div>
    )
}

export const NotFound = () => {
    return (
        <div className="mx-auto mt-5 text-center">
            <img src={pineappleLogo} className="sharesies-open-logo" alt="Sharesies Open" />
            <h3 className="mt-2">Page not found</h3>
            <p>Sorry, the page you were looking for doesn't exist</p>
            <p>You may have mistyped the address or the page may have moved.</p>
        </div>
    )
}
