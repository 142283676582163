const DEFAULT_FEATURES = {
    SEND_COMMS_TO_WATCHLIST: true,
}

// override default feature settings here
export const FEATURES_BY_COMPANY: Record<string, typeof DEFAULT_FEATURES> = {} as const

export function isFeatureEnabled(companyId: string, feature: keyof typeof DEFAULT_FEATURES): boolean {
    if (FEATURES_BY_COMPANY[companyId] != null) {
        return FEATURES_BY_COMPANY[companyId][feature]
    }
    return DEFAULT_FEATURES[feature]
}
